import { Column, Container, Grid, Section } from '../components/styled/layout'
import { Heading, Title } from '../components/styled/typography'
import React, { Component } from 'react'

import BreadCrumbs from '../components/UI/BreadCrumbs'
import Img from 'gatsby-image'
import JoinNewsletter from '../components/UI/JoinNewsletter'
import Layout from '../components/layout'
import { Line } from '../components/styled/ui'
import SEO from '../components/SEO/SEO'
import { graphql } from 'gatsby'
import styled from 'styled-components'

const HtmlWrapper = styled.div`
  & strong {
    font-family: 'Roboto slab', sans-serif;
    font-weight: 300;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 500px) {
    & strong {
      font-size: 1rem;
    }
  }
`

const StickyLabel = styled.div`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 40px;
  align-self: flex-start;
`

class UppdragTemplate extends Component {
  state = {}

  render() {
    const project = this.props.data.airtable.data
    const { location } = this.props
    console.log(location)
    return (
      <React.Fragment>
        <SEO title={project.Titel} />
        <Layout>
          <BreadCrumbs />
          <Container>
            <Section>
              <Grid>
                <Column>
                  <Title style={{ marginBottom: '0px' }}>
                    {project.Titel} <span>{project.Rubrik}.</span>
                  </Title>
                </Column>
              </Grid>
            </Section>
          </Container>
          <Section className="imageSection" padding="0px 0px">
            <Img fluid={project.Bilder.localFiles[0].childImageSharp.fluid} />
          </Section>
          <Container>
            <Section>
              <Grid columns="2fr 1fr">
                <Column>
                  <Heading>Beskrivning</Heading>
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{
                      __html: project.Beskrivning.childMarkdownRemark.html,
                    }}
                  />
                  <div style={{ display: 'flex' }}>
                    <p style={{ marginRight: '40px' }}>
                      <span style={{ color: '#00000060', display: 'block' }}>
                        Uppdragsgivare
                      </span>
                      {project.Uppdragsgivare[0].data.Organisation}
                    </p>
                    <p>
                      <span style={{ color: '#00000060', display: 'block' }}>
                        Genomförande
                      </span>
                      {project.Genomf_rdes}
                    </p>
                  </div>
                </Column>
                <Column>
                  <StickyLabel>
                    <Line />
                    <p>
                      <span style={{ color: '#00000060', display: 'block' }}>
                        01
                      </span>
                      Projektbeskrivning
                    </p>
                  </StickyLabel>
                </Column>
              </Grid>
            </Section>
          </Container>
          <Section className="imageSection" padding="0px 0px">
            <Img fluid={project.Bilder.localFiles[1].childImageSharp.fluid} />
          </Section>
          <Container>
            <Section>
              <Grid columns="2fr 1fr">
                <Column>
                  <Heading>Utmaning</Heading>
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{
                      __html: project.Utmaning.childMarkdownRemark.html,
                    }}
                  />
                </Column>
                <Column style={{ display: 'flex', flexDirection: 'column' }}>
                  <StickyLabel>
                    <Line />
                    <p>
                      <span style={{ color: '#00000060', display: 'block' }}>
                        02
                      </span>
                      Utmaningen
                    </p>
                  </StickyLabel>
                </Column>
              </Grid>
            </Section>
          </Container>
          <Section className="imageSection" padding="0px 0px">
            <Img fluid={project.Bilder.localFiles[2].childImageSharp.fluid} />
          </Section>
          <Container>
            <Section>
              <Grid columns="2fr 1fr">
                <Column>
                  <Heading>Vårt arbete</Heading>
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{
                      __html: project.Arbete.childMarkdownRemark.html,
                    }}
                  />
                </Column>
                <Column style={{ display: 'flex', flexDirection: 'column' }}>
                  <StickyLabel>
                    <Line />
                    <p>
                      <span style={{ color: '#00000060', display: 'block' }}>
                        03
                      </span>
                      Vårt arbete
                    </p>
                  </StickyLabel>
                </Column>
              </Grid>
            </Section>
          </Container>
          <Section className="imageSection" padding="0px 0px">
            {project.Bilder.localFiles[3] ? (
              <Img fluid={project.Bilder.localFiles[3].childImageSharp.fluid} />
            ) : null}
          </Section>
          <Container>
            <Section>
              <Grid columns="2fr 1fr">
                <Column>
                  <Heading>Resultat</Heading>
                  <HtmlWrapper
                    dangerouslySetInnerHTML={{
                      __html: project.Resultat.childMarkdownRemark.html,
                    }}
                  />
                </Column>
                <Column style={{ display: 'flex', flexDirection: 'column' }}>
                  <StickyLabel>
                    <Line />
                    <p>
                      <span style={{ color: '#00000060', display: 'block' }}>
                        04
                      </span>
                      Resultatet
                    </p>
                  </StickyLabel>
                </Column>
              </Grid>
            </Section>
          </Container>
        </Layout>
      </React.Fragment>
    )
  }
}

export default UppdragTemplate

export const pageQuery = graphql`
  query ProjetByID($id: String!) {
    airtable(id: { eq: $id }) {
      data {
        Titel
        Rubrik
        Path
        Genomf_rdes(formatString: "DD MMMM, YYYY", locale: "sv_SE")
        Uppdragsgivare {
          data {
            Organisation
          }
        }
        Beskrivning {
          childMarkdownRemark {
            html
          }
        }
        Utmaning {
          childMarkdownRemark {
            html
          }
        }
        Arbete {
          childMarkdownRemark {
            html
          }
        }
        Resultat {
          childMarkdownRemark {
            html
          }
        }
        Bilder {
          localFiles {
            childImageSharp {
              fluid(
                maxWidth: 1920
                maxHeight: 1200
                cropFocus: CENTER
                quality: 100
              ) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`
